html {
    background-color: aquamarine;
}

.logo1 img {
    width: 350px;
    height: 120px;
    position: fixed;
    top: 0px;
    left: 20px;
    z-index: 1000;
}
@media only screen and (max-width: 1500px) {
    .logo1 a {
           background-size:contain!important;            
           height: 1%;
           margin: 0;
           max-width: 417px;
           padding: 1% 0;
    }
} 