/* .comment-box-container {
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
} */

.comment-box {
    width: 99%;
    height: 308px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    resize: none;
    font-family: 'Times New Roman', Times, serif;
}
.comment-box1 {
    width: 100%;
    height: 500px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    resize: none;
}

.submit-button {
    margin-top: 12px;
}

.box1 {
    width: 35%;
}